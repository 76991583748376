<template>
  <div class="installments">
    <Header
      background="#F7F2FF"
      :heading="localize('banner')['how-to-work']['title']"
      :title="localize('banner')['how-to-work']['text']"
      :img="installmentBg"
      :btnText="localize('button')['register']"
      btnTo="/sign-up"
      padding-top="165px"
      padding-bottom="166px"
      absoluteImg="absolute-image"
    />

    <section id="scrollNav" class="installments_top">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-ml-4 col-md-5">
            <img src="../assets/img/installmentsImg.svg" alt="" />
          </div>

          <div class="col-xl-7 col-ml-7 col-md-7 wrapper">
            <h2>{{ localize("installment")["how-to-sign"]["title"] }}</h2>

            <ul>
              <li v-for="(document, index) in localize('installment')['how-to-sign']['documents']"
                  :key="index">
                <b>{{ document }}</b>
              </li>
            </ul>
          </div>

          <div class="col-xl-1 col-ml-1"></div>
        </div>
      </div>
    </section>

    <section class="installment_step">
      <div class="container">
        <h2>{{ localize("installment")["how-to-register"]["title"] }}</h2>

        <div class="row">
          <div
            class="col-xl-4 col-ml-4 col-lg-4 col-md-4 mb-4"
            v-for="(n, i) in step"
            :key="i"
          >
            <cards v-bind="n" />
          </div>
        </div>
      </div>
    </section>

    <section class="installment_card">
      <div class="container">
        <h2>{{ localize("how-to-buy")["title"] }}</h2>
        <div class="row">
          <div
            class="col-xl-3 col-ml-3 col-lg-6 mb-4"
            v-for="(n, i) in card"
            :key="i"
          >
            <cards v-bind="n" />
          </div>
        </div>
      </div>
    </section>
    <section class="installment_tovar">
      <div class="container">
        <h2>{{ localize("how-to-pay")["title"] }}</h2>

        <p v-html="localize('how-to-pay')['text']"></p>

        <div class="row">
          <div
            class="col-xl-4 col-ml-4 col-lg-4 mb-4"
            v-for="(n, i) in card2"
            :key="i"
          >
            <cards v-bind="n" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import cards from "@/components/cardBus.vue";
import fileAdd from "../assets/icons/installment/fileAdd.svg";
import credit_card from "../assets/icons/installment/credit-card-hand.svg";
import mobile_shopping from "../assets/icons/installment/mobile-shopping-cart.svg";
import LaptopIcon from "../assets/icons/laptop.svg";
import icon1 from "../assets/icons/installment/shopping-cart-add.svg";
import icon2 from "../assets/icons/installment/shopping-list.svg";
import icon3 from "../assets/icons/installment/file.svg";
import icon4 from "../assets/icons/installment/shipment-happy.svg";
import icon5 from "../assets/icons/credit-card.svg";
import icon6 from "../assets/icons/discount.svg";
import icon7 from "../assets/icons/5-star.svg";
import installmentBg from "../assets/img/girl_installments.webp";
import messageIcon from '@/assets/icons/message-icon.png'
import localize from "../filters/localize.filter";
import $ from "jquery";

export default {
  components: { Header, cards },
  data: () => ({
    installmentBg,
    fileAdd,
    credit_card,
    mobile_shopping,
    LaptopIcon,
    messageIcon,
    step: [],
    card: [],
    card2: []
  }),
  mounted() {
    this.createCard();
    window.addEventListener("scroll", () => {
      let $scroll = $(window).scrollTop();
      let scrollNav = document.querySelector("#scrollNav");

      if (scrollNav) {
        if ($scroll >= scrollNav.offsetTop) {
          $(".pay_navbar").addClass("scrollAnim");
        }
        if ($scroll <= scrollNav.offsetTop) {
          $(".pay_navbar").removeClass("scrollAnim");
        }
      }
    });
  },
  methods: {
    localize,
    createCard() {
      this.step = localize("installment")["step"];
      this.step.map((n, i) => {
        if (i == 0) {
          n.icon = this.LaptopIcon;
        } else if (i == 1) {
          n.icon = this.mobile_shopping;
        } else {
          n.otherIcon = true;
          n.icon = this.messageIcon;
        }
      });
      this.card = localize("how-to-buy")["cards"];
      this.card.map((n, i) => {
        if (i == 0) {
          n.icon = icon1;
        } else if (i == 1) {
          n.icon = icon2;
        } else if (i == 2) {
          n.icon = icon3;
        } else {
          n.icon = icon4;
        }
      });
      this.card2 = localize("how-to-pay")["cards"];
      this.card2.map((n, i) => {
        if (i == 0) {
          n.icon = icon5;
        } else if (i == 1) {
          n.icon = icon6;
        } else if (i == 2) {
          n.icon = icon7;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mb-4 {
  margin-bottom: 32px;
}

.installments_top {
  padding-top: 105px;
  padding-bottom: 73px;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 24px;
  }

  ul li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $muted;
    list-style: disc !important;
  }
}

.installment_step {
  padding-bottom: 200px;

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 64px;
  }

  .card_limit {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #333333;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .card_link {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 111.63%;
      display: flex;
      align-items: center;
      color: $main;
      text-decoration: none;
    }

    .card_wrapper {
      display: flex;
      align-items: center;
      padding: 13.5px;
      background: #fff6ee;
      border-radius: 8px;
    }
  }
}

.installment_card {
  padding-bottom: 200px;

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 64px;
  }

  .container .row {
    background: url('../assets/img/line.svg') no-repeat;
    background-position-x: 40px;
    background-position-y: 15px;
  }
}

.installment_tovar {
  padding-bottom: 204px;

  h2 {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #000000;
  }
}

@media (max-width: 1199px) {
  .installment_card .container .row {
    background: none;
  }
  .installments_top {
    padding-bottom: 70px;
    padding-top: 70px;
  }
  .installment_step {
    padding-bottom: 100px;
  }
  .installment_card {
    padding-bottom: 100px;
  }
  .installment_tovar {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .installments_top {
    h2 {
      font-size: 32px;
      line-height: auto;
    }

    p {
      font-size: 16px;
      line-height: auto;
    }
  }
  .installment_tovar {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .installments_top {
    h2 {
      font-size: 24px;
      line-height: auto;
    }

    p {
      font-size: 14px;
      line-height: auto;
    }
  }
}

@media (max-width: 575px) {
  .installments_top {
    padding-top: 40px;
    padding-bottom: 24px;

    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin: 12px 0px 8px !important;
    }

    .row {
      flex-wrap: wrap-reverse;
    }

    h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 8px;
    }

    p {
      font-size: 13px;
      line-height: 17px;
    }
  }
  .installment_step {
    padding-bottom: 72px;

    h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 8px;
    }

    .card_limit .card_wrapper {
      width: 48px;
      height: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .card_limit h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #333333;
      margin-top: 12px;
      margin-bottom: 16px;
    }
  }
  .installment_card {
    padding-bottom: 72px;
  }
  .installment_card h2 {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .installment_tovar {
    h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 8px;
    }

    p {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-bottom: 43px;
    }
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333% !important;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .col-ml-4 {
    flex: 0 0 auto;
    width: 25% !important;
  }
  .col-ml-7 {
    flex: 0 0 auto;
    width: calc(100% / 12 * 7) !important;
  }
  .col-ml-5 {
    width: calc(100% / 12 * 5) !important;
  }
  .col-ml-3 {
    width: calc(100% / 12 * 3) !important;
  }
}

@media (min-width: 1400px) {
  .col-xl-4 {
    width: calc(100% / 12 * 4) !important;
  }
  .col-xl-7 {
    width: calc(100% / 12 * 7) !important;
  }
  .col-xl-5 {
    width: calc(100% / 12 * 5) !important;
  }
  .col-xl-3 {
    width: calc(100% / 12 * 3) !important;
  }
}

@media (min-width: 1600px) {
  .col-xxl-3 {
    width: calc(100% / 12 * 3) !important;
  }
}
</style>
