<template>
  <div class="card">
    <div class="card-img">
      <div :class="{ 'card-wrapper': true, 'other': otherIcon }">
        <img :src="icon" alt="" />
      </div>
    </div>
    <div class="card-body">
      <div class="card-heading">
        <h3 :style="`margin:${styles};`">{{ heading }}</h3>
      </div>
      <div class="card-title">
        <h4 v-if="headline">{{ headline }}</h4>
        <p v-if="title" v-html="title"></p>
        <ul v-if="lists">
          <li v-for="(n, i) in lists" :key="i">{{ n }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    otherIcon: {
      type: Boolean
    },
    icon: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    lists: {
      type: Array
    },
    headline: {
      type: String
    },
    styles: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

.card {
  padding: 0 24px 0 0;

  &-wrapper {
    width: 72px;
    height: 72px;
    background: $main;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(-5px 5px 30px $box-shadow-color);

    &.other {
      background: #F7F2FF;
    }
  }

  h3 {
    @include font(24px, 700, 28px, $black, false);
    margin-bottom: 16px;
    margin-top: 32px;
  }

  h4 {
    @include font(16px, 700, 24px, $muted);
    letter-spacing: 0.01em;
  }

  p {
    @include font(16px, 400, 24px, $muted);
    letter-spacing: 0.01em;
    color: #333333;
  }

  ul {
    padding-left: 23px;

    li {
      list-style: disc;
      @include font(16px, 400, 24px, $muted);
      letter-spacing: 0.01em;
    }
  }
}

@media (max-width: 575px) {
  .card {
    .card-wrapper {
      width: 48px;
      height: 48px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin: 12px 0px 8px !important;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
    }

    ul li {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
    }
  }
}
</style>
